import React from 'react';

export enum FormType {
    checkbox,
    input,
    dropdown
}

interface IFormInfo {
    inputName: string;
    isRequired: boolean;
    formType: FormType;
    options?: string[];
}

interface IFormInputProps {
    formInfo: IFormInfo[];
}

export const FormInput: React.FC<IFormInputProps> = props => {
    const { formInfo } = props;

    const _renderLabel = (formInfo: IFormInfo) => (
        <label htmlFor={`${formInfo.inputName.replace(/ /, '-')}_form-item`} className={'eve-form-item__label'}>
            {formInfo.inputName} {formInfo.isRequired && <span className={'eve-form-item__label--required'}>*</span>}
        </label>
    );
    const _renderInput = (formInfo: IFormInfo) => (
        <div className={'eve-form-item__wrapper'}>
            {_renderLabel(formInfo)}
            <input type='text' id={`${formInfo.inputName.replace(/ /, '-')}_form-item`} className={'eve-form-item__input'} />
        </div>
    );

    const _renderDropdown = (formInfo: IFormInfo) => (
        <div className={'eve-form-item__wrapper'}>
            {_renderLabel(formInfo)}
            <select
                name={formInfo.inputName}
                id={`${formInfo.inputName.replace(/ /, '-')}_form-item`}
                className={'eve-form-item__dropdown'}
            >
                {formInfo.options && formInfo.options.map(opt => <option key={opt}>{opt}</option>)}
            </select>
        </div>
    );

    const _renderCheckbox = (formInfo: IFormInfo) => (
        <div className={'eve-form-item__wrapper'}>
            {_renderLabel(formInfo)}
            <input type={'checkbox'} />
        </div>
    );

    const _switchRender = (formInfo: IFormInfo) => {
        switch (formInfo.formType) {
            case FormType.input:
                return _renderInput(formInfo);
            case FormType.checkbox:
                return _renderCheckbox(formInfo);
            case FormType.dropdown:
                return _renderDropdown(formInfo);
        }
    };

    return <div className={'eve-form-item'}>{formInfo.map(fi => _switchRender(fi))}</div>;
};
